import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import TextField from "@mui/material/TextField";
import moment from "moment-timezone";

const inputStyle = {
  "& .MuiInputBase-root": {
    borderRadius: "12px",
  },
  "& .MuiFormLabel-root": {
    color: "gray",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black", // Change the outline color to orange
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
      borderWidth: "1px",
    },
  },
};

const TimePickerComponent = ({ onTimeChange }) => {
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);
  const [error, setError] = useState("");
  const [hoursDifference, setHoursDifference] = useState(null);

  const theme = createTheme();

  const handleClockInChange = (newValue) => {
    setError("");
    setClockIn(newValue);
    if (clockOut && newValue) {
      validateTimeRange(newValue, clockOut);
    } else {
      notifyParent(newValue, clockOut, null);
    }
  };

  const handleClockOutChange = (newValue) => {
    setError("");
    setClockOut(newValue);
    if (clockIn && newValue) {
      validateTimeRange(clockIn, newValue);
    } else {
      notifyParent(clockIn, newValue, null);
    }
  };

  const validateTimeRange = (inTime, outTime) => {
    if (moment(outTime).isBefore(moment(inTime))) {
      setError("Clock Out time must be after Clock In time.");
      setHoursDifference(null);
      notifyParent(inTime, outTime, null);
    } else {
      calculateHoursDifference(inTime, outTime);
    }
  };

  const calculateHoursDifference = (inTime, outTime) => {
    const duration = moment.duration(moment(outTime).diff(moment(inTime)));
    const hours = duration.asHours().toFixed(2);
    setHoursDifference(hours);
    notifyParent(inTime, outTime, hours);
  };

  const notifyParent = (inTime, outTime, difference) => {
    if (onTimeChange) {
      onTimeChange({
        clockIn: inTime
          ? inTime.clone().tz("UTC", true).toDate().toISOString()
          : null,
        clockOut: outTime
          ? outTime.clone().tz("UTC", true).toDate().toISOString()
          : null,
        hoursDifference: difference,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="flex flex-col w-full gap-4">
          <MobileDateTimePicker
            label="Clock In"
            value={clockIn}
            onChange={handleClockInChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error || ""}
                sx={inputStyle}
              />
            )}
          />

          <MobileDateTimePicker
            label="Clock Out"
            value={clockOut}
            onChange={handleClockOutChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error || ""}
                sx={inputStyle}
              />
            )}
          />

          {hoursDifference !== null && (
            <div>
              <strong>Hours Worked:</strong> {hoursDifference} hours
            </div>
          )}

          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default TimePickerComponent;
