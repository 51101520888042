import React, { useRef } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  useGetJobDetails,
  useGetJobTypes,
  useGetSalesPerson,
  useUpdateJob,
} from "../apiCalls/jobApiCalls";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

export default function EditJob() {
  const titleInputElement = useRef();
  const amountInputElement = useRef();
  const typeInputElement = useRef();
  const salesInputElement = useRef();
  const noteInputElement = useRef();
  const collectedInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { jobId } = useParams();

  const { isLoading: isJobDetailsLoading, data: jobDetails } = useGetJobDetails(
    jobId,
    token
  );

  const { data: salesPersons } = useGetSalesPerson(token);
  const { data: jobTypes } = useGetJobTypes(token);

  const { mutate: updateJobMutate, isLoading: isUpdateJobLoading } =
    useUpdateJob();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      jobId: jobId,
      token: token,
      title: titleInputElement.current?.value,
      amount: amountInputElement.current?.value,
      salesId: salesInputElement.current?.value,
      typeId: typeInputElement.current?.value,
      note: noteInputElement.current?.value,
      collected: collectedInputElement.current?.value,
    };

    updateJobMutate(data);
  };

  return (
    <>
      {isJobDetailsLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="pb-8 pt-10">
                  <div className="space-y-3">
                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Title of Job
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="text"
                          className="outline-none w-full text-base font-medium text-black placeholder-black"
                          name="title"
                          defaultValue={jobDetails.data.data.title}
                          ref={titleInputElement}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Job Type
                      </label>
                      <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                        <select
                          name="workerIds"
                          className="w-full outline-none"
                          ref={typeInputElement}
                          defaultValue={jobDetails.data.data.typeId}
                        >
                          {jobTypes?.data.data.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.type === "Percentage"
                                ? `${type.title} @ ${type.rate}%`
                                : `${type.title} @ $${type.rate} per ${type.type}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Items/Amount/Area of Job
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="number"
                          className="outline-none w-full text-base font-medium text-black placeholder-black"
                          name="amount"
                          defaultValue={jobDetails.data.data.amount}
                          ref={amountInputElement}
                          step=".01"
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Sales Person
                      </label>
                      <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                        <select
                          name="workerIds"
                          className="w-full outline-none"
                          ref={salesInputElement}
                          defaultValue={jobDetails.data.data.salesId?._id}
                        >
                          {salesPersons?.data.data.map((worker) => (
                            <option key={worker._id} value={worker._id}>
                              {worker.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Note
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="text"
                          className="outline-none w-full text-base font-medium text-black placeholder-black"
                          name="note"
                          defaultValue={jobDetails.data.data.note}
                          ref={noteInputElement}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label
                        className="text-base font-medium text-gray-500"
                        htmlFor=""
                      >
                        Collected
                      </label>
                      <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                        <select
                          name="workerIds"
                          className="w-full outline-none"
                          ref={collectedInputElement}
                          defaultValue={jobDetails.data.data.collected}
                        >
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-4">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isUpdateJobLoading ? "...Is Saving" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
